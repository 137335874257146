import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { ParagraphWrapper } from '../ParagraphWrapper';
import { SmartLink } from '../../SmartLink';
import { t } from '../../../domain/services/configService';
import { theme } from '../../../domain/theme';
import { ContainerFluid } from '../../responsive/atoms/ContainerFluid';
import { GridColumn, GridRow } from '../../responsive/atoms/Grid';
import { Image } from '../../Image';
import { Button } from '../../responsive/atoms/Button';
import { IconReload } from '../../Icon/IconReload';
import { useBreakpoint } from '../../../hooks/useBreakpoint';

const styles = {
  container: css`
    width: 100%;
    max-width: 84rem;
    padding: ${theme.spacing.parse('0 $base $xl3 $base')};
    margin: 0 auto;
    a {
      color: #000;
    }
    .hidden-mobile {
      ${theme.mqMax.mobile} {
        display: none;
      }
    }
    .hidden-tablet {
      ${theme.mqMax.tablet} {
        display: none;
      }
    }
    .hidden-small_desktop {
      ${theme.mqMax.small_desktop} {
        display: none;
      }
    }
    .hidden-large_desktop {
      ${theme.mqMax.large_desktop} {
        display: none;
      }
    }
  `,
  imageContainer: css`
    overflow: hidden;
    picture {
      transition: all 800ms;
    }
    picture:hover {
      transform: scale(1.1);
    }
  `,
  textWrapper: css`
    padding: ${theme.spacing.parse('$xs2 $xs')};
    background: #fff;
    border: 1px solid #ccc;
    border-top: none;
  `,
  link: css`
    display: block;
    margin: ${theme.spacing.parse('$xs2 0')};
    ${theme.font.family('regularWeb')};
    font-size: clamp(0.875em, 2vw + 1rem, 1em);
    font-weight: ${theme.font.weight('l')};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `,
  cta: css`
    display: block;
    margin: ${theme.spacing.parse('$xs 0')};
    ${theme.font.family('regularWeb')};
    font-size: clamp(0.625em, 2vw + 1rem, 0.75em);
    font-weight: ${theme.font.weight('l')};
    letter-spacing: ${theme.letterSpacing('base')};
    text-transform: uppercase;
  `,
  button: css`
    text-align: center;
    padding: ${theme.spacing.parse('$xl 0 $s 0')};
  `
};

/*
Not all countries should be visible at first load. The logic is:
- mobile: 5 items
- tablet: 6 items (3 rows of 2 items)
- small_desktop: 9 items (3 rows of 3 items)
- large_desktop: 12 items (3 rows of 4 items)
*/
const visibilityConfig = {
  mobile: 5,
  tablet: 6,
  small_desktop: 9,
  large_desktop: 12
};

const getClassNames = index =>
  Object.keys(visibilityConfig)
    .filter(breakpoint => index + 1 > visibilityConfig[breakpoint])
    .map(breakpoint => `hidden-${breakpoint}`)
    .join(' ');

const TravelGuideCountryListParagraphInner = ({ data, colors }) => {
  const [breakpoint] = useBreakpoint();
  const [pagination, setPagination] = useState(-1);

  // During first rendering, render all items and hide them with CSS to avoid CLS problems.
  // On consequent renderings, show/hide items based on breakpoint and pagination.
  const countryList =
    pagination === -1
      ? data.countryList.data
      : data.countryList.data.slice(
          0,
          visibilityConfig[breakpoint.name] * (pagination + 1)
        );

  const areAllItemsVisible =
    data.countryList.data.length === countryList.length;

  const loadPagination = () => setPagination(pagination + 1);

  useEffect(() => {
    setPagination(0);
  }, []);

  return countryList.length > 0 ? (
    <ContainerFluid backgroundColor={colors.background}>
      <div css={styles.container}>
        <GridRow gutter="base">
          {countryList.map((country, index) => (
            <GridColumn
              width={[12, 6, 4, 3]}
              key={`TravelGuideCountryListParagraph-${country.url}`}
              className={pagination === 0 ? getClassNames(index) : null}
            >
              <SmartLink to={country.url}>
                <div css={styles.imageContainer}>
                  <Image
                    entity={country.image}
                    aspect="3x2"
                    maxWidths={{
                      mobile: 315,
                      tablet: 425,
                      small_desktop: 250,
                      large_desktop: 300
                    }}
                  />
                </div>
                <div css={styles.textWrapper}>
                  <span css={styles.link}>
                    {country.title.replace(`${t('Travel Guide')}: `, '')}
                  </span>
                  <span css={styles.cta}>{t('See guide')}</span>
                </div>
              </SmartLink>
            </GridColumn>
          ))}
        </GridRow>

        {!areAllItemsVisible && (
          <div css={styles.button}>
            <Button
              onClick={loadPagination}
              padding="$xs $base"
              color={colors.background === 'black' ? 'black' : 'white'}
              icon={<IconReload size={18} display="block" />}
            >
              {t('LOAD MORE')}
            </Button>
          </div>
        )}
      </div>
    </ContainerFluid>
  ) : (
    <div />
  );
};

TravelGuideCountryListParagraphInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  colors: PropTypes.objectOf(PropTypes.any).isRequired
};

export const TravelGuideCountryListParagraph = ParagraphWrapper(
  TravelGuideCountryListParagraphInner
);
